@use '@angular/material' as mat;
@import "assets/css/partials/includes";
@import "assets/css/partials/theme/color";

@include mat.core();


// Light Palettes
$primary : mat.define-palette(mat.$deep-purple-palette, A400);
$accent : mat.define-palette(mat.$amber-palette);

// Common Palette
$warn : mat.define-palette(mat.$red-palette);

// Dark Palettes
$primary-dark : mat.define-palette(mat.$amber-palette, A400);
$accent-dark : mat.define-palette(mat.$deep-orange-palette);


$theme :mat.define-light-theme($primary, $accent, $warn);
$theme: get-modified-light-theme($theme);
@include mat.all-component-themes($theme);
@include includes-mixin($theme);

$theme-dark  :mat.define-dark-theme($primary-dark, $accent-dark, $warn);

.dark-theme{  
  $theme-dark : get-modified-dark-theme($theme-dark);
  @include mat.all-component-themes($theme-dark);
  @include includes-mixin($theme-dark);
}



/* You can add global styles to this file, and also import other style files */

:root {
  font-size: 10px;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  -webkit-print-color-adjust: exact;
}

.font-montserrat {
  font-family: "Montserrat", sans-serif;
}

.mat-drawer-container {
  min-height: 100vh;
}

#wrapper {
  position: relative;
}




















html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
