   .p-0 {
        padding: 0!important
    }
    
    .pt-0,
    .py-0 {
        padding-top: 0!important
    }
    
    .pr-0,
    .px-0 {
        padding-right: 0!important
    }
    
    .pb-0,
    .py-0 {
        padding-bottom: 0!important
    }
    
    .pl-0,
    .px-0 {
        padding-left: 0!important
    }
    
    .p-1 {
        padding: .25rem!important
    }
    
    .pt-1,
    .py-1 {
        padding-top: .25rem!important
    }
    
    .pr-1,
    .px-1 {
        padding-right: .25rem!important
    }
    
    .pb-1,
    .py-1 {
        padding-bottom: .25rem!important
    }
    
    .pl-1,
    .px-1 {
        padding-left: .25rem!important
    }
    
    .p-2 {
        padding: .5rem!important
    }
    
    .pt-2,
    .py-2 {
        padding-top: .5rem!important
    }
    
    .pr-2,
    .px-2 {
        padding-right: .5rem!important
    }
    
    .pb-2,
    .py-2 {
        padding-bottom: .5rem!important
    }
    
    .pl-2,
    .px-2 {
        padding-left: .5rem!important
    }
    
    .p-3 {
        padding: 1rem!important
    }
    
    .pt-3,
    .py-3 {
        padding-top: 1rem!important
    }
    
    .pr-3,
    .px-3 {
        padding-right: 1rem!important
    }
    
    .pb-3,
    .py-3 {
        padding-bottom: 1rem!important
    }
    
    .pl-3,
    .px-3 {
        padding-left: 1rem!important
    }
    
    .p-4 {
        padding: 1.5rem!important
    }
    
    .pt-4,
    .py-4 {
        padding-top: 1.5rem!important
    }
    
    .pr-4,
    .px-4 {
        padding-right: 1.5rem!important
    }
    
    .pb-4,
    .py-4 {
        padding-bottom: 1.5rem!important
    }
    
    .pl-4,
    .px-4 {
        padding-left: 1.5rem!important
    }
    
    .p-5 {
        padding: 3rem!important
    }
    
    .pt-5,
    .py-5 {
        padding-top: 3rem!important
    }
    
    .pr-5,
    .px-5 {
        padding-right: 3rem!important
    }
    
    .pb-5,
    .py-5 {
        padding-bottom: 3rem!important
    }
    
    .pl-5,
    .px-5 {
        padding-left: 3rem!important
    }