// Color Variables

// $blue : #2962ff;
// $red : red;
// $green : #03bc6d;
// $orange : #ffd400;
// $violet : #c121dc;


$blue : #2962ff; // Delivered
$red : red; // Rejected
$green : #03bc6d; // Accepted
$orange : #ffd400; // Pending
$violet : #c121dc; // Prepared
$brown: #dda15e; // On The Way
$light-blue: #168aad;
$gray: #6c757d;


// Color Box Mixins

@mixin colorBox($color) {
    background: rgba($color, 0.2);
    color: $color;
}
@mixin colorBorderBox($color, $opacity, $style) {
    background: rgba($color, $opacity);
    color: $color;
    border: 0.1rem $style $color;
    border-radius: 0.5rem;
    display: block;
    padding: 0.5rem 0.8rem;
}